import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericActionsComponent } from './generic-actions/generic-actions.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    GenericActionsComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    FormsModule
  ],
  exports : [
    GenericActionsComponent
  ]
})
export class CommonComponentsModule { }
