import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
// import { TablesComponent } from '../common/tables/tables.component';
import { MatInputModule } from '@angular/material/input';
import { ErrorStateMatcher, MatNativeDateModule, MatRippleModule, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RadioBtnComponent } from './radio-btn/radio-btn.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { VideoUploadComponent } from './video-upload/video-upload.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { QuillModule } from 'ngx-quill';
import { SliderComponent } from './slider/slider.component';
import { SingleCheckboxComponent } from './single-checkbox/single-checkbox.component';
import { MatSliderModule } from '@angular/material/slider';
import { ChipsComponent } from './chips/chips.component';
import { MatChipsModule } from '@angular/material/chips';
import { ToggleBtnComponent } from './toggle-btn/toggle-btn.component';
import { UploadImagesComponent } from './upload-images/upload-images.component';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { TimeDatePickerComponent } from './time-date-picker/time-date-picker.component';
import { CounterComponent } from './counter/counter.component';
import { AgeComponent } from './age/age.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { ImagesUploadComponent } from './images-upload/images-upload.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';

import { MultiValueDropdownComponent } from './multi-value-dropdown/multi-value-dropdown.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { LoaderComponent } from './loader/loader.component';
import { MatCardModule } from '@angular/material/card';

import { ViewInputComponent } from './view-input/view-input.component';





import { RatingComponent } from './rating/rating.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { SafePipe } from 'app/pipe/safe.pipe';

@NgModule({
  declarations: [InputComponent, DatePickerComponent, DropdownComponent, FileUploadComponent, RadioBtnComponent, CheckboxComponent, TimePickerComponent, DatePickerComponent, VideoUploadComponent, TextAreaComponent, RichTextEditorComponent, SliderComponent, SingleCheckboxComponent, ChipsComponent, ToggleBtnComponent, UploadImagesComponent, TimeDatePickerComponent, CounterComponent, AgeComponent, ToggleButtonComponent, ImagesUploadComponent, ConfirmationDialogComponent, SlideToggleComponent, MultiValueDropdownComponent, SuccessDialogComponent, LoaderComponent, RatingComponent, DateRangePickerComponent, UploadDocumentComponent,SafePipe,ViewInputComponent],
  imports: [
    CommonModule,
    MatInputModule,
    QuillModule.forRoot(),
    FormsModule, MatFormFieldModule, ReactiveFormsModule, MatIconModule, MatButtonModule, MatRadioModule,
    MatAutocompleteModule,
    MatButtonModule,
    NgxMatDatetimePickerModule,
    MatFormFieldModule,
    MatSliderModule,
    MatChipsModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    NgSelectModule,
    MatCardModule
    
    // ChartsModule

  ],
  providers: [{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }],
  exports: [InputComponent, FileUploadComponent, RadioBtnComponent, DropdownComponent, CheckboxComponent, TimePickerComponent, DatePickerComponent, VideoUploadComponent, TextAreaComponent, RichTextEditorComponent, SliderComponent, SingleCheckboxComponent, ChipsComponent, ToggleBtnComponent, UploadImagesComponent, TimeDatePickerComponent, AgeComponent, ImagesUploadComponent, ConfirmationDialogComponent, SlideToggleComponent, MultiValueDropdownComponent, SuccessDialogComponent, LoaderComponent, RatingComponent,DateRangePickerComponent,UploadDocumentComponent,ViewInputComponent]

})
export class ControllersModule { }
