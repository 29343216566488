import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { CommonComponentsModule } from 'app/components/common-components/common-components.module';
import { GenericActionsComponent } from 'app/components/common-components/generic-actions/generic-actions.component';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        NotificationsComponent,
        UserComponent,
        NgIf,
        MatIconModule,
        MatButtonModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        SearchComponent,
        ShortcutsComponent,
        MessagesComponent,
        RouterOutlet,
        QuickChatComponent,
        CommonComponentsModule,
    ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        let obje: Navigation = {
            compact: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'basic',
                    icon: 'heroicons_outline:chart-pie',
                    link: '/dashboard',
                },
            ],
            default: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'collapsable',
                    link: '/dashboard',
                    children: [
                        {
                            id: 'dashboard.project',
                            title: 'Project',
                            type: 'basic',
                            link: '/dashboard/project',
                        },
                    ],
                },
                {
                    id: 'user',
                    title: 'User Management',
                    type: 'collapsable',
                    link: '/user',
                    children: [
                        {
                            id: 'user.pet',
                            title: 'Pet Parent',
                            type: 'basic',
                            link: '/user/pet',
                        },
                        {
                            id: 'user.collaborator',
                            title: 'Partner',
                            type: 'basic',
                            link: '/user/collaborator',
                        },
                    ],
                },
                {
                    id: 'workspace',
                    title: 'Workspace Management',
                    type: 'collapsable',
                    link: '/workspace',
                    children: [
                        {
                            id: 'workspace.workspaces',
                            title: 'Workspaces',
                            type: 'basic',
                            link: '/workspace/workspaces',
                        },
                        {
                            id: 'workspace.metrics',
                            title: 'Metrics',
                            type: 'basic',
                            link: '/workspace/metrics',
                        },
                    ],
                },
                {
                    id: 'appointment',
                    title: 'Appointments',
                    type: 'basic',
                    link: '/appointment',
                },
                {
                    id: 'Rolls',
                    title: 'Rolls',
                    type: 'basic',
                    link: '/rolls',
                },
                
                {
                    id: 'push',
                    title: 'Push Notification',
                    type: 'basic',
                    link: '/notification',
                },
                {
                    id: 'coupon',
                    title: 'Coupon Management',
                    type: 'basic',
                    link: '/coupon',
                },
                {
                    id: 'education',
                    title: 'Education',
                    type: 'collapsable',
                    link: '/education',
                    children: [
                        {
                            id: 'education.webinar',
                            title: 'Webinar',
                            type: 'basic',
                            link: '/education/webinar',
                        },
                        {
                            id: 'education.course',
                            title: 'Course',
                            type: 'basic',
                            link: '/education/course',
                        },
                    ],
                },

                {
                    id: 'miscellaneous',
                    title: 'Miscellaneous',
                    type: 'collapsable',
                    link: '/miscellaneous',
                    children: [
                        {
                            id: 'miscellaneous.blog',
                            title: 'Blog/Educational Blog',
                            type: 'basic',
                            link: '/miscellaneous/blog',
                        },
                        {
                            id: 'miscellaneous.events',
                            title: 'Events',
                            type: 'basic',
                            link: '/miscellaneous/events',
                        },
                        {
                            id: 'miscellaneous.advertisment',
                            title: 'Advertisment',
                            type: 'basic',
                            link: '/miscellaneous/advertisment',
                        },
                        {
                            id: 'miscellaneous.faqs',
                            title: 'FAQs',
                            type: 'basic',
                            link: '/miscellaneous/faqs',
                        },
                        {
                            id: 'miscellaneous.admin',
                            title: 'Admin',
                            type: 'basic',
                            link: '/miscellaneous/admin',
                        },
                        {
                            id: 'miscellaneous.account',
                            title: 'Account',
                            type: 'basic',
                            link: '/miscellaneous/account',
                        },
                    ],
                },
            ],
            futuristic: [
                {
                    id: 'example',
                    title: 'Example',
                    type: 'basic',
                    icon: 'heroicons_outline:chart-pie',
                    link: '/example',
                },
            ],
            horizontal: [
                {
                    id: 'example',
                    title: 'Example',
                    type: 'basic',
                    icon: 'heroicons_outline:chart-pie',
                    link: '/example',
                },
            ],
        };
        this.navigation = obje;
        // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) => {
        //         this.navigation = navigation;
        //         console.log(navigation);
        //         this.navigation
        //         let obj: any = {
        //             "id": "user",
        //             "title": "User Management",
        //             "type": "basic",
        //             // "icon": "heroicons_outline:chart-pie",
        //             "link": "/user"
        //         }
        //         navigation.default.push(obj)

        //     });

        // Subscribe to the user service
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    filter(event) {}
    add(event) {}
    export(event) {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
