import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent {
  title = 'angular';
  @Input() form:FormGroup ;
  @Input() controller : any;

  editorConfig = {
    base_url : '/tinymce',
    suffix : '.min',
    plugins : 'lists link image table wordcount'
  }
}
