import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent {
  selectedFile: File = null;
  previewUrl: any = null;
  @Input() form;
  errorMessage: string = '';
  fileBlobUrl: string = null;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() fileName: EventEmitter<string> = new EventEmitter();


  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {


  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];

    // File validation
    const mimeType = this.selectedFile.type;
    const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (mimeType !== 'application/pdf' && !validImageTypes.includes(mimeType)) {
      this.errorMessage = 'Only PDF or image files (PNG, JPEG) are allowed!';
      this.selectedFile = null;
      this.previewUrl = null;
      return;
    } else {
      this.errorMessage = '';
    }

    const reader = new FileReader();

    if (mimeType === 'application/pdf') {
      // PDF preview
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
        this.fileBlobUrl = URL.createObjectURL(blob);
        this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(this.fileBlobUrl);
      };
      reader.readAsArrayBuffer(this.selectedFile);
    } else if (validImageTypes.includes(mimeType)) {
      // Image preview
      reader.onload = () => {
        // this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        const dataUrl = reader.result as string;
        const byteString = atob(dataUrl.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
          uintArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([uintArray], { type: mimeType });
        this.fileBlobUrl = URL.createObjectURL(blob);
        this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(this.fileBlobUrl);
      };
      reader.readAsDataURL(this.selectedFile);
    }

    this.onUpload();
  }

  onUpload() {
    if (this.selectedFile) {
      this.onSelect.emit(this.selectedFile);
      this.fileName.emit(this.selectedFile.name);
      console.log('File uploaded:', this.selectedFile.name);
    } else {
      console.error('No file selected.');
    }
  }




  openInNewTab() {
    this.form.get('document').value
    if (this.fileBlobUrl) {
      window.open(this.fileBlobUrl, '_blank');
    } else if (this.form.get('document').value) {
      window.open(this.form.get('document').value, '_blank');
    } else {
      console.error('No file blob URL found.');
    }
  }



  delete() {
    console.log("delete");

    if (this.selectedFile || this.form.get('document').valid) {
      this.selectedFile = null;
      this.fileBlobUrl = null;
      this.previewUrl = null;
      console.log(this.form);

      this.form.get('document').setValue('')
    }
  }

}
