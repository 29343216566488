import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {
  @Input() min:number;
  @Input() max : number;
  @Input() step : number;
  @Input() form : FormArray|FormGroup;
  @Input() controller : any;
  @Input() readonly : false;

  formatLabel(value: number): string {
    return `${value}`;
  }
}
