<label style="font-size: 14px;font-weight: 500">{{controller['label']}}</label>
<div>
    <ng-container>
        <input type="file" id="file" multiple (change)="getFileDetails($event)">

        <!-- <button mat-stroked-button color="primary" (click)="uploadFiles()">Upload</button> -->
    </ng-container>
</div>
<!-- <div class="images">
    <div *ngFor="let f of files">
        <img [src]="f" alt="{{ f.name }}" style="max-height: 100px; max-width: 150px; padding: 2px;" />
    </div>
</div> -->
<div class="image-container">
    <div class="row" *ngFor="let image of files; let i = index" style="display: flex;">
        <div class="image-wrapper" *ngFor="let img of files.slice(i, i + 3)">
            <div class="image-1">
                <img [src]="img" alt="Image">
                <button mat-icon-button color="primary" [attr.title]="deleteButtonLabel?deleteButtonLabel:null"
                    (click)="removeFile(event,image,i)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>