import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationsService {

  constructor() { }

  stringValidator(): ValidatorFn {//stringOnly
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      // Check if the value is not a number or is a string with at least one letter
      const isNotOnlyNumbers = isNaN(value) || /[a-zA-Z]/.test(value);

      if (!isNotOnlyNumbers) {
        // Return validation error if the value is only numbers
        return { 'stringOnly': true };
      }

      // Validation passed
      return null;
    };
  }
  // } ValidatorFn {//stringOnly
    // return (control: AbstractControl): { [key: string]: any } | null => {
    //   const value = control.value;
      // Check if the value is not a number or is a string with at least one letter
      // const isNotOnlyNumbers = isNaN(value) || /[a-zA-Z]/.test(value);
      // if (!isNotOnlyNumbers) {
        // Return validation error if the value is only numbers
      //   return { 'stringOnly': true };
      // }
      // Validation passed
  //     return null;
  //   };
  // }
  //formarray length check
  minLengthArray(min: number) {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (c instanceof FormArray && c.length >= min) {
        return null;
      }
      return { 'minLengthArray': { valid: false } };
    };
  }

  atLeastOneSelectedValidator(): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
      const controls = (formGroup as FormGroup).controls;
      const isValid = Object.keys(controls).some(key => controls[key].value);

      return isValid ? null : { atLeastOneSelected: true };
    };
  }

  imageDimensionsValidator(maxWidth: number, maxHeight: number): ValidatorFn {
    return (control: AbstractControl): Promise<{ [key: string]: any } | null> => {
      return new Promise((resolve, reject) => {
        if (control.value && control.value instanceof File) {
          const file: File = control.value;
          const img = new Image();
          img.src = URL.createObjectURL(file);

          img.onload = () => {
            const width = img.width;
            const height = img.height;
            URL.revokeObjectURL(img.src);
            console.log(width);
            console.log(height);


            if (width !== maxWidth || height !== maxHeight) {
              console.log("error");

              reject({ imageDimensions: true });
            } else {

              resolve(null);
            }
          };

          img.onerror = () => {
            resolve({ imageDimensions: true });
          };
        } else {
          resolve(null);
        }
      });
    };
  }

  mobileNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const mobileNumberRegex = /^[1-9]\d{9}$/; // Regular expression to match a 10-digit number
      const isValid = mobileNumberRegex.test(control.value);
      return isValid ? null : { 'invalidMobileNumber': { value: control.value } };
    };
  }

  pincodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pincodeRegex = /^[1-9][0-9]{2}\s?[0-9]{3}$/; // Regular expression to match a 6-digit pin code
      const isValid = pincodeRegex.test(control.value);
      return isValid ? null : { 'invalidPincode': { value: control.value } };
    };
  }

  locationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      // Regular expression to match latitude and longitude format: ±[0-90].\d{1,6}, ±[0-180].\d{1,6}
      const locationRegex = /^-?([0-9]|[1-8][0-9]|90)\.\d{1,50},\s*-?((([0-9]|[1-9][0-9]|1[0-7][0-9])(\.\d{1,50})?)|180(\.0{1,6})?)$/;
      const isValid = locationRegex.test(value);
      return isValid ? null : { 'invalidLocationFormat': { value: control.value } };
    };
  }

  startTimeGreaterThanClosingTimeValidator(startTimeControl: any, closingTimeControl: any): ValidatorFn {
    return (formGroup: FormGroup): { [key: string]: any } | null => {
      if (!startTimeControl || !closingTimeControl) {
        return null;
      }

      const startTime = startTimeControl.value;
      const closingTime = closingTimeControl.value;

      if (startTime !== null && closingTime !== null && startTime >= closingTime) {
        closingTimeControl.setErrors({ 'startTimeNotGreaterThanClosingTime': true });
        return { 'startTimeNotGreaterThanClosingTime': true };
      } else {
        closingTimeControl.setErrors(null);
      }

      return null;
    };
  }

  gstNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const isValid = value.length === 15 && value.charAt(13) === 'Z';
      return isValid ? null : { 'invalidGSTNumber': { value } };
    };
  }

  ifscValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        // If the control value is empty, return null (no validation error)
        return null;
      }

      // Regular expression to match IFSC code pattern
      const ifscPattern = /^[A-Z]{4}[0][\d]{6}$/;

      // Check if the control value matches the pattern
      if (!ifscPattern.test(control.value)) {
        // If it doesn't match, return a validation error object
        return { ifsc: true };
      }

      // If the control value matches the pattern, return null
      return null;
    };
  }

  accountNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        // If the control value is empty, return null (no validation error)
        return null;
      }

      // Regular expression to match the account number pattern
      const accountPattern = /^\d{8,20}$/;

      // Check if the control value matches the pattern
      if (!accountPattern.test(control.value)) {
        // If it doesn't match, return a validation error object
        return { accountNumber: true };
      }

      // If the control value matches the pattern, return null
      return null;
    };
  }

  panValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        // If the control value is empty, return null (no validation error)
        return null;
      }

      // Regular expression to match the PAN number pattern
      const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

      // Check if the control value matches the pattern
      if (!panPattern.test(control.value)) {
        // If it doesn't match, return a validation error object
        return { pan: true };
      }

      // If the control value matches the pattern, return null!
      return null;
    };
  }

  alphanumericValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const alphanumericRegex = /^[a-zA-Z0-9]*$/;

      if (!alphanumericRegex.test(value)) {
        return { 'alphanumeric': true };
      }
      return null;
    };
  }
  tagValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (!value) {
        // If the control value is empty, consider it valid
        return null;
      }

      // Define a regular expression for the pattern of a valid tag
      const tagPattern = /^#[a-zA-Z0-9]+$/; // This pattern ensures the tag starts with '#' and contains only alphanumeric characters

      if (!tagPattern.test(value)) {
        return { 'invalidTag': true }; // Return an error if the value doesn't match the pattern
      }

      return null; // The tag is valid
    };
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // Regular expression to match a valid email address
      const emailRegex = /^(?!^[0-9]+@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValid = emailRegex.test(control.value);
      return isValid ? null : { 'invalidEmail': { value: control.value } };
    };
  }

  designationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const stringOnlyRegex = /^(?!.*\s{2,})(?!^\s)(?!.*\s$)[a-zA-Z.()0-9 ]*$/;
      const isValid = stringOnlyRegex.test(value);
      if (!isValid) {
        return { 'stringOnly': true };
      }
      return null;
    };  
  }
  isOnlyStringValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const stringOnlyRegex = /^(?!.*\s{2,})(?!^\s)(?!.*\s$)[a-zA-Z. ]*$/;
      const isValid = stringOnlyRegex.test(value);
      if (!isValid) {
        return { 'stringOnly': true };
      }
      return null;
    };
  }
  cityValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const isValid = /^(?!.*\s{2,})(?!^\s)(?!.*\s$)[a-zA-Z. ]*$/.test(value) && value.length <= 28;
      if (!isValid) {
        return { 'cityValid': true };
      }
      return null;
    };
  }
  priceGreaterThanValidator(workspaceDetails: { price: number }): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const inputValue = parseFloat(control.value);
      console.log("🚀 ~ ValidationsService ~ return ~ inputValue:", inputValue)
      const minValue = workspaceDetails.price;
      console.log("🚀 ~ ValidationsService ~ return ~ minValue:", minValue)
      return inputValue > minValue ? null : { 'priceTooLow': true };
    };
  }
}
