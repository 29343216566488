import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-single-checkbox',
  templateUrl: './single-checkbox.component.html',
  styleUrls: ['./single-checkbox.component.scss']
})
export class SingleCheckboxComponent {
  @Input() form : FormGroup;
  @Input() controller : any;
}
