import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Controller } from 'app/interfaces/controller';

@Component({
  selector: 'app-toggle-btn',
  templateUrl: './toggle-btn.component.html',
  styleUrls: ['./toggle-btn.component.scss']
})
export class ToggleBtnComponent {
  @Input() form: FormGroup;
  @Input() controller: Controller;
  @Input() multiple: boolean;

  ngOnInit() {
  }

  ngOnChanges() {
    console.log(this.multiple);
    // this.form.get(this.controller['name']).reset()
    console.log(this.form.get(this.controller['name']));
    

  }

}
