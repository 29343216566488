import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
// import { Label } from 'ng2-charts';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent {
  @Input() controller : any
  @Input() ratingForm: FormGroup;
  stars = Array(5).fill(0);
  
  rate(star: number) {
    this.ratingForm.get('rating').setValue(star);
  }
}
