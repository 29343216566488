import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loaderState = this.isLoading.asObservable();
  
  constructor() { 
    console.log("###");
    
  }

  showLoader(): void {
    this.isLoading.next(true);
  }

  hideLoader(): void {
    this.isLoading.next(false);
  }

  getLoaderVal(): BehaviorSubject<boolean> {
    return this.isLoading;
  }
  
}
