<form [formGroup]="form" class="wrapper" *ngIf="form">
    <mat-form-field style="width: 48%;">
        <mat-label>Years:</mat-label>
        <button mat-icon-button color="primary" (click)="incrementYears()">
            <mat-icon>add</mat-icon>
        </button>
        <input matInput formControlName="year" readonly>
        <!-- <span>{{ form.get('year').value }}</span> -->
        <button mat-icon-button color="primary" (click)="decrementYears()">
            <mat-icon>remove</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field style="width: 48%;">
        <mat-label>Months:</mat-label>
        <button mat-icon-button color="primary" (click)="incrementMonths()">
            <mat-icon>add</mat-icon>
        </button>
        <input matInput formControlName="month" readonly>
        <!-- <span>{{ form.get('month').value }}</span> -->
        <button mat-icon-button color="primary" (click)="decrementMonths()">
            <mat-icon>remove</mat-icon>
        </button>
    </mat-form-field>
</form>