import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Controller } from 'app/interfaces/controller';

@Component({
  selector: 'app-radio-btn',
  templateUrl: './radio-btn.component.html',
  styleUrls: ['./radio-btn.component.scss']
})
export class RadioBtnComponent {
  @Input() form : FormArray|FormGroup;
  @Input() controller : any;
  @Input() disabled : boolean = false;
  @Output() onValueChange : EventEmitter<any> = new EventEmitter();
  option :string;
  constructor(){
  }
  ngOnInit(){    
  }

  onRadioChange(event: any) {
    this.onValueChange.emit(event.value)
    // Here you can call a method to reset the forms in the child components if necessary
  }
}
