import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-age',
  templateUrl: './age.component.html',
  styleUrls: ['./age.component.scss']
})
export class AgeComponent {
  @Input() form: FormGroup;
  constructor() {

  }

  ngOnInit() {
    // console.log(this.form);

  }

  // Function to increment years
  incrementYears() {
    this.form.get('year').setValue(this.form.get('year').value + 1);
  }

  // Function to decrement years
  decrementYears() {
    let currentYears = this.form.get('year').value;
    let currentMonths = this.form.get('month').value;
    currentYears--;
    if (currentYears < 0) {
      currentYears = 0;
      currentMonths = 0; // Reset months to 0 if years go below 0
    }
    this.form.get('year').setValue(currentYears)
    this.form.get('month').setValue(currentMonths)

  }

  // Function to increment months
  incrementMonths() {
    let currentMonths = this.form.get('month').value;
    let currentYears = this.form.get('year').value;
    currentMonths++;
    if (currentMonths >= 12) {
      currentMonths = 0;
      currentYears++;
    }
    
    this.form.get('year').setValue(currentYears)
    this.form.get('month').setValue(currentMonths)
  }

  // Function to decrement months
  decrementMonths() {
    let currentMonths = this.form.get('month').value;
    let currentYears = this.form.get('year').value;
    currentMonths--;
    if (currentMonths < 0) {
      currentMonths = 11
      currentYears--;
    }
    this.form.get('year').setValue(currentYears)
    this.form.get('month').setValue(currentMonths)
  }
}
