import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
  @Input() controller  :any;
  @Input() form : FormGroup;
  @Input()min : any;
  @Input()max : any
  @Output() DateChange : EventEmitter<string> = new EventEmitter()

  onDateChange(){
    this.DateChange.emit('')
  }
}
