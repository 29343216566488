import { Component, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Controller } from 'app/interfaces/controller';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent{//implements OnChanges
  @Input() form: FormGroup;
  @Input() controller: Controller;
  @Input() multiple: boolean = false;


  loading: boolean = true; // Add a loading state
  myForm: FormGroup;
  options = [];
  options$: Observable<any[]>;
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {    
    this.options = this.controller['options'];
    this.options$ = of(this.controller['options']);
    // this.form.get(this.controller['name']).valueChanges.subscribe((change)=>{
    //   console.log(change);
      
    // })
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("changes --> ", changes);
    if (changes.controller?.currentValue) {
      this.options = changes.controller.currentValue.options;
    }
    // if (changes['data']) {
    //   console.log('Previous:', changes['data'].previousValue);
    //   console.log('Current:', changes['data'].currentValue);
    //   console.log('First change:', changes['data'].firstChange);
    // }
  }

  
}
