<form *ngIf="form && controller" [formGroup]="form">
    <mat-label style="color: black; font-weight: 500;">{{controller['label']}}*</mat-label>
    <mat-radio-group class="example-radio-group" [formControlName]="controller['name']" (change)="onRadioChange($event)" style="padding-top: 4%;">
        <div *ngFor="let option of controller['options']">
            <mat-radio-button class="example-radio-button" [value]="option['value']" >{{option['name']}}</mat-radio-button>
        </div>
    </mat-radio-group>
    <!-- <div
        *ngIf="this.form.get(controller['name']).invalid && (this.form.get(controller['name']).dirty || this.form.get(controller['name']).touched)">
        <div *ngIf="this.form.get(controller['name']).hasError('required')">
            <mat-error>field is <strong>required</strong></mat-error>
        </div>
    </div> -->
</form>