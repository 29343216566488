<form *ngIf="form && controller" [formGroup]="form">
    <mat-form-field class="chip-list">
        <mat-label [ngStyle]="{'color': changed ? 'red' : 'black'}">{{controller['label']}}*</mat-label>
        <mat-chip-grid #chipGrid aria-label="Enter Tags" [formArrayName]="controller['name']">
            <mat-chip-row *ngFor="let tag of form.get(controller['name']).controls; let i=index;"
                (removed)="remove(tag)" [editable]="true" (edited)="edit(tag, $event)"
                [aria-description]="'press enter to edit ' + tag.value" [formGroupName]="i">
                {{tag.value}}
                <button matChipRemove [attr.aria-label]="'remove ' + tag.value">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input placeholder="New Tag..." [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)" />
        </mat-chip-grid>
    </mat-form-field>
    <!-- <mat-error *ngIf="form.get(controller['name']).errors && form.get(controller['name']).errors.required">
        {{ getErrorMessage() }}
    </mat-error> -->
    <mat-error *ngIf="isTag">
        Field is <strong> Invalid.</strong>.
    </mat-error>
    <!-- *ngIf="myForm.get('items').invalid && myForm.get('items').errors?.invalidOnce" -->


    <!-- <ng-template #found>
        <mat-error>Tag is <strong> Invalid.</strong>.</mat-error>
    </ng-template>

    <ng-template #continue></ng-template> -->
</form>