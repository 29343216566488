import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import {environment} from '../../../environments/environment'
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthService
{
    configUrl: string =  environment.apiUrl + 'admin/v1/super/admin/';//env
    private _authenticated: boolean = false;
    adminId = ''

    /**
     * Constructor
     */
    constructor(
        private _router : Router,
        private _httpClient: HttpClient,
        private _userService: UserService,
        
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        // console.log("set access token ");
        // token = "bvur"
        sessionStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        // console.log("get access token");
        
        return sessionStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }
        //api/auth/sign-in
        let url = this.configUrl + 'login'
        return this._httpClient.post(url, credentials).pipe(
            switchMap((response: any) =>
            {
                //console.log(response['id']);
                sessionStorage.setItem('adminId', response['id'])
                // this.adminId = response['id']
                console.log("signup.........",response);                
                // console.log(response);
                // Store the access token in the local storage
                this.accessToken = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response;

                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // console.log('signUpWithToken');
        
        // Sign in using the token
        // if(this.accessToken){
        //     return of(this._authenticated);
        // }
        // else{
        //     return of(false)
        // }
        return of(true)
        
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
    // localStorage.removeItem('authToken');
    // localStorage.removeItem('userEmail');
        // Remove the access token from the local storage
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('userEmail');

        this._router.navigate(['/auth/sign-in'])

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return //this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return //this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }        
        // console.log(this.signInUsingToken());
        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
