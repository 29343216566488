<mat-form-field class="example-form-field">
  <mat-label>{{controller['label']}}</mat-label>
  <mat-date-range-input [formGroup]="form" [rangePicker]="campaignOnePicker" [comparisonStart]="form.value.start"
    [comparisonEnd]="form.value.end">
    <input matStartDate placeholder="Start date" formControlName="start">
    <input matEndDate placeholder="End date" formControlName="end">
  </mat-date-range-input>
  <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
  <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
</mat-form-field>