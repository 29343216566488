import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable, finalize } from 'rxjs';
import { LoaderService } from 'app/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) {
    console.log("called");
    
  }
  
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
): Observable<HttpEvent<any>> {
    this.loaderService.showLoader();
    console.log("called showloaded");
    return next.handle(req).pipe(
        finalize(() => {
            this.loaderService.hideLoader();
        })
    );
}
}
