<mat-form-field *ngIf="form && controller" [formGroup]="form">
    <mat-label [ngStyle]="{'color': changed ? 'red' : 'black'}">{{controller['label']}}</mat-label>
    <input matInput [formControlName]="controller['name']" [placeholder]="controller['placeholder']"
        [errorStateMatcher]="matcher" [readonly]="readonly" [type]="type" [maxlength]="maxlength">
</mat-form-field>
<!-- [type]='controller["type"]' -->
<div style="height: 20%;padding: 2px;"
    *ngIf="this.form.get(controller['name']).invalid && (this.form.get(controller['name']).dirty || this.form.get(controller['name']).touched)">
    <div *ngIf="this.form.get(controller['name']).hasError('required')">
        <mat-error>Field is <strong>required</strong></mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).hasError('pattern')">
        <mat-error>Field must be a <strong>Number</strong></mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).errors && this.form.get(controller['name']).errors.stringOnly && form.get(controller['name']).touched">
        <mat-error>Invalid <strong> Format.</strong></mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).errors && this.form.get(controller['name']).errors.cityValid">
        <mat-error>Inavlid city name</mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).errors && this.form.get(controller['name']).errors.ifsc">
        <mat-error>Invalid <strong>IFSC</strong>format.</mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).errors && this.form.get(controller['name']).errors.accountNumber">
        <mat-error>Invalid <strong>account number</strong>,should be 8-20 digits.</mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).errors && this.form.get(controller['name']).errors.priceTooLow">
        <mat-error>Service price should be greater than price field</mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).errors && this.form.get(controller['name']).errors.max">
        <mat-error>Number must be less than <strong>{{maxlength ? maxlength : '50'}}</strong></mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).errors.email && this.form.get(controller['name']).touched">
        <mat-error>Wrong <strong>email.</strong></mat-error>
    </div>
    <div
        *ngIf="this.form.get(controller['name']).errors?.invalidMobileNumber && this.form.get(controller['name']).touched">
        <mat-error>Invalid mobile number format. Please enter a <strong>10-digit </strong>number.</mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).errors?.invalidPincode && this.form.get(controller['name']).touched">
        <mat-error>Invalid pin code format. Please enter a <strong>6-digit </strong>pin code.</mat-error>
    </div>
    <div
        *ngIf="this.form.get(controller['name']).errors?.invalidLocationFormat && this.form.get(controller['name']).touched">
        <mat-error>Invalid location format. Please enter latitude and longitude
            <!-- in the format: ±[0-90].\d[1-6], ±[0-180].\d[1,6] -->
        </mat-error>
    </div>

    <mat-error *ngIf="this.form.get(controller['name']).errors?.invalidGSTNumber">
        GST Number must be 15 characters long and the 14th character must be 'Z'.
    </mat-error>
</div>