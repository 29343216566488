<form *ngIf="form && controller" [formGroup]="form" style="width: 100%;">
    <mat-form-field style="width: 100%;">
        <mat-label>{{controller['label']}}</mat-label>
        <input matInput [matDatepicker]="picker" [formControlName]="controller['name']" [min]="min" [max]="max" (dateChange)="onDateChange()">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</form>