import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidationsService } from 'app/services/validations.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Output() onFileSelect: EventEmitter<string> = new EventEmitter()
  @Input() form: any;
  @Input() controller: any;
  @ViewChild('fileUpload')
  fileUpload: ElementRef;
  inputFileName: string;
  @Input()
  deleteButtonIcon = 'close'
  @Input()
  multiple;
  @Input()
  accept = "video/*";//"image/png"
  @Input()
  chooseLabel = 'Upload'
  @Input()
  files: File[] = []
  @Input()
  key: string
  @Input() maxWidth;
  @Input() maxHeight;
  @Input() isView: boolean = false;
  error: string

  constructor(
    private sanitizer: DomSanitizer,
    private validationsService: ValidationsService
  ) {


  }
  ngOnChanges() {
    this.files = [];
    this.form.get(this.controller['name']).valueChanges.subscribe((change) => {
      console.log(this.form.get(this.controller['name']));

    })
    if (this.form.get(this.controller['name']).value) {
      console.log(this.form);

      this.files.push(this.form.get(this.controller['name']).value)
    }
  }
  ngOnInit() {
    console.log(this.controller);

  }

  onClick(event) {
    if (this.fileUpload)
      this.fileUpload.nativeElement.click()
  }

  onInput(event) {

  }

  // onFileSelected(event) {
  //   // console.log(event);
  //   console.log(event['target']);

  //   let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
  //   for (let i = 0; i < files.length; i++) {
  //     let file = files[i];
  //     let accept;
  //     if (this.accept == "video/*") {
  //       accept = 'video/';
  //     } else if (this.accept = "image/*") {
  //       accept = 'image/'
  //     }
  //     if (file.type.startsWith(accept)) {
  //       if (this.validate(file)) {
  //         const img = new Image();
  //         img.src = URL.createObjectURL(file);
  //         if (this.controller['name'] == 'coverImage' || this.controller['name'] == 'image' || this.controller['name'] == 'thumbImage' || this.controller['name'] == 'aadhar' || this.controller['name'] || 'pan' || this.controller['name'] == 'doctorsLicence' || this.controller['name'] == 'qualificationCertificate' || this.controller['name'] == '') {
  //           img.onload = () => {
  //             const width = img.width;
  //             const height = img.height;
  //             URL.revokeObjectURL(img.src);
  //             console.log(width);
  //             console.log(height);
  //             console.log(this.maxHeight);
  //             console.log(this.maxWidth);

  //             if (this.maxWidth && this.maxHeight) {
  //               if (width !== this.maxWidth || height !== this.maxHeight) {
  //                 const canvas = document.createElement('canvas');
  //                 const ctx = canvas.getContext('2d');
  //                 canvas.width = 1900;
  //                 canvas.height = 1080;
  //                 ctx.drawImage(img, 0, 0, 1920, 1080);

  //                 // Convert the canvas content to a Blob
  //                 canvas.toBlob((blob) => {
  //                   const resizedFile = new File([blob], file.name, { type: file.type });
  //                   this.processFile(resizedFile);
  //                 }, file.type);
  //                 // console.log("error 000000000");
  //                 // this.error = 'Image dimensions should be ' + this.maxWidth + ' X ' + this.maxHeight + ' pixels.'
  //                 // this.controller['name']
  //               } else {
  //                 this.error = ''
  //                 //      if(this.isImage(file)) {
  //                 file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
  //                 //      }
  //                 // if (!this.isMultiple()) {
  //                 //   this.files = []
  //                 // }
  //                 this.files.push(files[i]['objectURL']['changingThisBreaksApplicationSecurity']);
  //                 //  }
  //                 this.files.forEach((f) => {
  //                   let control = this.form.get(this.controller['name']) as FormControl;
  //                   // control.setValidators(this.validationsService.imageDimensionsValidator(this.maxWidth,this.maxHeight))
  //                   control.setValue(files[0]);
  //                   // control.updateValueAndValidity();
  //                 })
  //                 this.onFileSelect.emit(this.controller['name']);

  //               }
  //             } else {
  //               this.error = ''
  //               //      if(this.isImage(file)) {
  //               file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
  //               //      }
  //               // if (!this.isMultiple()) {
  //               //   this.files = []
  //               // }
  //               this.files.push(files[i]['objectURL']['changingThisBreaksApplicationSecurity']);
  //               //  }
  //               this.files.forEach((f) => {
  //                 let control = this.form.get(this.controller['name']) as FormControl;
  //                 // control.setValidators(this.validationsService.imageDimensionsValidator(this.maxWidth,this.maxHeight))
  //                 control.setValue(files[0]);
  //                 // control.updateValueAndValidity();
  //               })
  //               this.onFileSelect.emit(this.controller['name']);
  //             }
  //           };

  //         } else {
  //           this.error = ''
  //           //      if(this.isImage(file)) {
  //           file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
  //           //      }
  //           // if (!this.isMultiple()) {
  //           //   this.files = []
  //           // }
  //           this.files.push(files[i]['objectURL']['changingThisBreaksApplicationSecurity']);
  //           //  }
  //           this.files.forEach((f) => {
  //             let control = this.form.get(this.controller['name']) as FormControl;
  //             // control.setValidators(this.validationsService.imageDimensionsValidator(this.maxWidth,this.maxHeight))
  //             control.setValue(files[0]);
  //             // control.updateValueAndValidity();
  //           })
  //           this.onFileSelect.emit(this.controller['name']);
  //         }
  //       }
  //     } else {
  //       // setError
  //     }

  //     //if(!this.isFileSelected(file)){
  //     //}
  //   }
  // }

  onFileSelected(event) {
    // console.log(event);
    console.log(event['target']);

    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let accept;
      if (this.accept == "video/*") {
        accept = 'video/';
      } else if (this.accept = "image/*") {
        accept = 'image/'
      }
      if (file.type.startsWith(accept)) {
        if (this.validate(file)) {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          if (this.controller['name'] == 'coverImage' || this.controller['name'] == 'image' || this.controller['name'] == 'thumbImage') {
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              URL.revokeObjectURL(img.src);
              console.log(width);
              console.log(height);

              if (this.maxWidth && this.maxHeight) {
                if (width !== this.maxWidth || height !== this.maxHeight) {
                  console.log("error 000000000");
                  this.error = 'Image dimensions should be ' + this.maxWidth + ' X ' + this.maxHeight + ' pixels.'
                  // this.controller['name']
                } else {
                  this.error = ''
                  //      if(this.isImage(file)) {
                  file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
                  //      }
                  // if (!this.isMultiple()) {
                  //   this.files = []
                  // }
                  this.files.push(files[i]['objectURL']['changingThisBreaksApplicationSecurity']);
                  //  }
                  this.files.forEach((f) => {
                    let control = this.form.get(this.controller['name']) as FormControl;
                    // control.setValidators(this.validationsService.imageDimensionsValidator(this.maxWidth,this.maxHeight))
                    control.setValue(files[0]);
                    // control.updateValueAndValidity();
                  })
                  this.onFileSelect.emit(this.controller['name']);

                }
                // if (this.controller['name'] == 'aadhar' || this.controller['name'] == 'pan' || this.controller['name'] == 'doctorsLicence' || this.controller['name'] == 'qualificationCertificate') {
                //   const canvas = document.createElement('canvas');
                //   const ctx = canvas.getContext('2d');
                //   canvas.width = 1900;
                //   canvas.height = 1080;
                //   ctx.drawImage(img, 0, 0, 1920, 1080);

                //   // Convert the canvas content to a Blob
                //   canvas.toBlob((blob) => {
                //     const resizedFile = new File([blob], file.name, { type: file.type });
                //     this.processFile(resizedFile);
                //   }, file.type);
                //   // console.log("error 000000000");
                //   // this.error = 'Image dimensions should be ' + this.maxWidth + ' X ' + this.maxHeight + ' pixels.'
                //   // this.controller['name']
                // } else {
                  
                // }
              } else {
                this.error = ''
                //      if(this.isImage(file)) {
                file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
                //      }
                // if (!this.isMultiple()) {
                //   this.files = []
                // }
                this.files.push(files[i]['objectURL']['changingThisBreaksApplicationSecurity']);
                //  }
                this.files.forEach((f) => {
                  let control = this.form.get(this.controller['name']) as FormControl;
                  // control.setValidators(this.validationsService.imageDimensionsValidator(this.maxWidth,this.maxHeight))
                  control.setValue(files[0]);
                  // control.updateValueAndValidity();
                })
                this.onFileSelect.emit(this.controller['name']);
              }
            };

          } else {
            this.error = ''
            //      if(this.isImage(file)) {
            file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
            //      }
            // if (!this.isMultiple()) {
            //   this.files = []
            // }
            this.files.push(files[i]['objectURL']['changingThisBreaksApplicationSecurity']);
            //  }
            this.files.forEach((f) => {
              let control = this.form.get(this.controller['name']) as FormControl;
              // control.setValidators(this.validationsService.imageDimensionsValidator(this.maxWidth,this.maxHeight))
              control.setValue(files[0]);
              // control.updateValueAndValidity();
            })
            this.onFileSelect.emit(this.controller['name']);
          }
        }
      } else {
        // setError
      }

      //if(!this.isFileSelected(file)){
      //}
    }
  }



  processFile(file) {
    this.error = '';
    file.objectURL = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
    this.files.push(file.objectURL['changingThisBreaksApplicationSecurity']);
    this.files.forEach((f) => {
      let control = this.form.get(this.controller['name']) as FormControl;
      control.setValue(file);
    });
    this.onFileSelect.emit(this.controller['name']);
  }
  removeFile(event, file) {
    let ix
    if (this.files && -1 !== (ix = this.files.indexOf(file))) {
      this.files.splice(ix, 1)
      this.clearInputElement()
      this.form.get(this.controller['name']).reset();
      console.log(this.form);

    }
  }

  validate(file: File) {
    for (const f of this.files) {
      if (f.name === file.name
        && f.lastModified === file.lastModified
        && f.size === f.size
        && f.type === f.type
      ) {
        return false
      }
    }
    return true
  }

  clearInputElement() {
    this.fileUpload.nativeElement.value = ''
  }


  isMultiple(): boolean {
    return this.multiple
  }


}
