<div *ngIf="form && controller" [formGroup]="form" class="slide-toggle-container">
  <span class="toggle-label" style="color: black; font-weight: 500;">{{controller['label']}}</span>
  <div [hidden]="isView">
    <mat-slide-toggle [formControlName]="controller['name']" (change)="onToggleChange($event)" [disabled]="disabled"></mat-slide-toggle>
  </div>
  <div *ngIf="isView">
    <span>{{value ? 'Yes' : 'No'}}</span>
    <!-- <mat-slide-toggle [(ngModel)]="value" [ngModelOptions]="{standalone: true}" [disabled]="true"></mat-slide-toggle> -->
  </div>
</div>

<!-- <mat-form-field *ngIf="form && controller" [formGroup]="form">
    <mat-label>{{controller['label']}}</mat-label>
    <input matInput [formControlName]="controller['name']"
        [placeholder]="controller['placeholder']" [errorStateMatcher]="matcher">
</mat-form-field> -->