import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-generic-actions',
  templateUrl: './generic-actions.component.html',
  styleUrls: ['./generic-actions.component.scss']
})
export class GenericActionsComponent {

  @Input() actionName: string = 'Add';
  @Input()showAll : boolean ;
  @Input() activeOrAll : boolean = false
  @Output() showData : EventEmitter<string> = new EventEmitter();
  @Output() addEvent: EventEmitter<string> = new EventEmitter();
  @Output() exportEvent: EventEmitter<string> = new EventEmitter();
  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  searchTerm: any;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    // Disable ripple effect on initialization
    this.disableRipple();
  }
  ngOnInit(){
    // this.searchTerm = sessionStorage.getItem('viewPetAppointmentId');
    // this.onSearchInput();
  }

  onSearchInput() {
    this.filterEvent.emit(this.searchTerm);
  }

  showDataClick(){
    this.showData.emit();
  }

  disableRipple() {
    const button = this.elementRef.nativeElement.querySelector('button.mat-button');
    if (button) {
      this.renderer.setAttribute(button, 'disableRipple', 'true');
    }
  }

  filter() {

  }

  add() {
    this.addEvent.emit("add")
  }

  export() {
    this.exportEvent.emit();
  }
}
