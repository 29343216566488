<mat-label style="color: black; font-weight: 500;">{{!this.form.get('document').value ? 'Upload
  Document' : ''}}</mat-label>
<div class="wrapper" *ngIf="!this.form.get('document').value" style="height: 60px;">
  <div>
    Click or drag/drop file
  </div>
  <input type="file" (change)="onFileSelected($event)" style="position: absolute;">
  <!-- <button mat-raised-button color="primary" (click)="onUpload()" [disabled]="!selectedFile">Upload</button> -->
  <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>

</div>
<div style="display: flex;flex-direction: row;justify-content: space-between; " *ngIf="fileBlobUrl || this.form.get('document').value">
  <button mat-flat-button  (click)="openInNewTab()"
    style="text-decoration: underline;color:blue;">Open Document in New Tab</button>
  <!--  -->
  <button mat-icon-button color="primary" (click)="delete()">
    <mat-icon>close</mat-icon>
  </button>

</div>


<!-- <mat-card class="card" *ngIf="previewUrl">
    <mat-card-content>
      <iframe [src]="previewUrl | safe" width="100%" height="400px"></iframe>
    </mat-card-content>
  </mat-card> -->