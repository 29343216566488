<form *ngIf="form && controller" [formGroup]="form">
    <mat-label style="color: black;font-weight: 500;">{{ controller['label'] ? controller['label'] + '*' : ''}}</mat-label>
    <mat-button-toggle-group [formControlName]="controller['name']" [aria-label]="controller['label']" [multiple]="multiple">
        <div class="buttons-wrapper">
            <mat-button-toggle *ngFor="let option of controller['options']" [value]="option['value']" style="padding: 2px;margin: 2px;">{{option['name']}}</mat-button-toggle>
        </div>
    </mat-button-toggle-group>
</form>


