// email-storage.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailStorageService {
  private emailKey = 'email'; // Key to store in sessionStorage

  setEmail(email: string): void {
    sessionStorage.setItem(this.emailKey, email);
  }

  getEmail(): string {
    return sessionStorage.getItem(this.emailKey) ?? '';
  }
}
