import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Controller } from 'app/interfaces/controller';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent {
  @Input() form : FormGroup;
  @Input() controller : Controller;
  @Input() maxlength : number

}
