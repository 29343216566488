import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-view-input',
  templateUrl: './view-input.component.html',
  styleUrls: ['./view-input.component.scss']
})
export class ViewInputComponent {
  @Input() label : string;
  @Input() value : string;
  @Input() isVertical: boolean;

  constructor() { }

  formatVal(val: string): string {
    if (val) {
      let retVal = val.toString();
      retVal = retVal.replace('true', 'Yes');
      retVal = retVal.replace('false', 'No');
  
      retVal = retVal.replace(/<\/?p[^>]*>/g, '');
  
      return retVal;
    }
    return '-';
  }
}
