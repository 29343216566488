<form [formGroup]="ratingForm">
    <mat-label style="color: black; font-weight: 500;">{{controller['label']}}*</mat-label>
    <div class="rating-container">
        <button mat-icon-button *ngFor="let star of stars; let i = index" (click)="rate(i + 1)">
            <mat-icon>{{ i < ratingForm.get('rating').value ? 'star' : 'star_border' }}</mat-icon>
        </button>
    </div>
    <p style="color: black; font-weight: 500;">Your rating: {{ ratingForm.get('rating').value }}</p>
</form>

