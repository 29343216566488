<!-- <script type="text/javascript" src="/bundle.js"></script> -->
<div *ngIf="isLoader">
    <app-loader></app-loader>
</div>
<!-- <div>{{ observableData$ | async }}</div> -->
<!-- <app-loader></app-loader> -->
<!-- <div class="loader-overlay" *ngIf="isLoader">
    <div class="loader"></div>
</div> -->
<router-outlet></router-outlet>
