<div class="wrapper">
    <div class="left">
        <!-- <button mat-button (click) = "filter()"><mat-icon aria-hidden="false" aria-label="Example home icon"
                fontIcon="filter_alt"></mat-icon>Filter
        </button> -->
        <button mat-button (click)="showDataClick()" *ngIf="activeOrAll">{{showAll? 'Show Active' : 'Show All'}}
        </button>
    </div>
    <div class="right">
        <mat-form-field appearance="fill" style="width: 45%;height: 90%;">
            <input matInput [(ngModel)]="searchTerm" (input)="onSearchInput()" placeholder="Search" />
            <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
        <button (click)="export()" mat-button><span class='material-icons'
                style="padding-right: 0px !important;">save_alt</span>Export</button>
        <button mat-button (click)="add()"><span class='material-icons'>add</span>{{actionName}}
        </button>
    </div>
</div>