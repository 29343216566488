import { Component, Input, inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ValidationsService } from 'app/services/validations.service';



export interface Tags {
  name: string;
}

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent {
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @Input() form: FormGroup;
  isTag:boolean = false;
  @Input() controller: any;
  @Input() changed:false;
  constructor(
    private fb: FormBuilder,
    private validationsService : ValidationsService,
  ) {

  }
  ngOnInit() {
    this.selectedChips.valueChanges.subscribe((change)=>{
      for (let el of this.selectedChips.controls) {
        if (el.errors && (el.errors?.invalidTag || el.errors?.stringOnly)) {
          this.isTag = true;
          break; // Break the loop once the element is found
        }else{
          this.isTag = false
        }
      }
    })
  }

  announcer = inject(LiveAnnouncer);

  add(event: MatChipInputEvent): void {
    let formArray = this.form.get(this.controller['name']) as FormArray;
    const value = (event.value || '').trim();
    // Add our fruit
    // let control = this.fb.control(value);
    if (value) {
      if(this.controller['name']=='rollTags' || this.controller['name']=='tags' ){
        formArray.push(this.fb.control(value,[this.validationsService.tagValidator(),]))
      }else{
        formArray.push(this.fb.control(value,[this.validationsService.stringValidator(),]))
      }
      //this.fb.control(value)
    }
    // Clear the input value
    event.chipInput!.clear();
  }

  remove(tag: any): void {    
    let formArray = this.form.get(this.controller['name']) as FormArray;
    const index = formArray.controls.findIndex((x,i:number)=> x.value == tag.value);
    if (index >= 0) {
      formArray.removeAt(index)
      this.announcer.announce(`Removed ${tag}`);
    }
  }

  edit(tag: any, event: MatChipEditedEvent) {
    const value = event.value.trim();
    // Remove fruit if it no longer has a name
    if (!value) {
      this.remove(tag);
      return;
    }
    // Edit existing fruit
    let formArray = this.form.get(this.controller['name']) as FormArray;
    const index = formArray.controls.findIndex((x,i:number)=> x.value == tag.value);
    if (index >= 0) {
      formArray.controls[index].setValue(value)
    }
  }

  get selectedChips() {
    return this.form.get(this.controller['name']) as FormArray;
  }
  getErrorMessage() {
    const selectedChipsArray = this.selectedChips;
    if (selectedChipsArray.errors && selectedChipsArray.errors.required) {
      return 'You must select at least one chip';
    }
    return '';
  }

}
