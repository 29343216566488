<!-- <form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill">
    <mat-label>Choose Images</mat-label>
    <input type="file" (change)="onFileSelected($event)" multiple>
  </mat-form-field>
</form> -->
<!-- <form [formGroup]="form" (ngSubmit)="onSubmit()" style="width: 100%;">
    <mat-form-field appearance="fill" style="width: 500px;">
        <mat-label>Choose Images</mat-label>
    </mat-form-field> -->
<!-- <button mat-raised-button color="primary" type="submit">Upload</button> -->
<!-- </form> -->
<div>
    <input style="width: 100%;" matInput placeholder="Choose Images" readonly>
    <input type="file" (change)="onFileSelected($event)" multiple accept="image/*">
</div>

<div class="image-container" *ngIf="uploadedImages.length > 0">
    <div *ngFor="let image of uploadedImages; let i = index" class="image-item">
        <!-- <div class="image-1"> -->
        <img [src]="image" alt="Uploaded Image">
        <button mat-mini-fab color="warn" (click)="removeImage(i,image)" class="icon-remove">
            <mat-icon>close</mat-icon>
        </button>
        <!-- </div> -->

    </div>
</div>