<mat-form-field *ngIf="form && controller" [formGroup]="form">
    <mat-label style="color: black; font-weight: 450;">{{controller['label']}}</mat-label>
    <textarea [type]='controller["type"]' matInput [formControlName]="controller['name']"
    [placeholder]="controller['placeholder']" [maxlength]="maxlength?maxlength : 1000"></textarea>
</mat-form-field>
<!-- <div style="height: 20%;"
    *ngIf="this.form.get(controller['name']).invalid && (this.form.get(controller['name']).dirty || this.form.get(controller['name']).touched)">
    <div *ngIf="this.form.get(controller['name']).hasError('required')">
        <mat-error>field is <strong>required</strong></mat-error>
    </div>
</div> -->