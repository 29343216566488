<div *ngIf="form && controller">
    <mat-label style="font-weight: 500;" [ngStyle]="{'color': changed ? 'red' : 'black'}">{{controller['label']}}</mat-label>
    <div [formGroup]="form" class="time-picker">
        <input [ngxTimepicker]="toggleTimepicker" [disableClick]="true" [formControlName]="controller['name']" readonly style="z-index: 1000 !important;">
        <ngx-material-timepicker-toggle [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
        <ngx-material-timepicker #toggleTimepicker></ngx-material-timepicker>
    </div>
</div>
<div style="height: 20%;"
    *ngIf="this.form.get(controller['name']).invalid && (this.form.get(controller['name']).dirty || this.form.get(controller['name']).touched)">
    <div *ngIf="this.form.get(controller['name']).hasError('required')">
        <mat-error>Field is <strong>required</strong></mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).hasError('pattern')">
        <mat-error>Field must be a <strong>Number</strong></mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).errors && this.form.get(controller['name']).errors.stringOnly">
        <mat-error>Field must be a <strong>string.</strong></mat-error>
    </div>
    <div *ngIf="this.form.get(controller['name']).errors.email && this.form.get(controller['name']).touched">
        <mat-error>Wrong <strong>email.</strong></mat-error>
    </div>
    <div
        *ngIf="this.form.get(controller['name']).errors?.invalidMobileNumber && this.form.get(controller['name']).touched">
        <div class="text-danger">Invalid mobile number format. Please enter a 10-digit number.</div>
    </div>
    <div *ngIf="this.form.get(controller['name']).errors?.invalidPincode && this.form.get(controller['name']).touched">
        <div class="text-danger">Invalid pin code format. Please enter a 6-digit pin code.</div>
    </div>
    <div
        *ngIf="this.form.get(controller['name']).errors?.invalidLocationFormat && this.form.get(controller['name']).touched">
        <div class="text-danger">Invalid location format. Please enter latitude and longitude in the format:
            ±[0-90].\d[1-6], ±[0-180].\d[1,6]</div>
    </div>
    <!-- startTimeNotGreaterThanClosingTime -->
    <div
        *ngIf="this.form.get(controller['name']).errors?.startTimeNotGreaterThanClosingTime && this.form.get(controller['name']).touched">
        <mat-error>End time must be greater than start time.</mat-error>
    </div>
</div>